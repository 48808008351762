<script>
// import axios from "axios";

import DropZone from "@/components/widgets/dropZone";
import { ref } from "vue";

// import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import Layout from "../../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Add-product component
 */
export default {
  page: {
    title: "Add Product",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  setup() {
    let dropzoneFile = ref("");
    let url = ref(null);
    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
      url = URL.createObjectURL(e.dataTransfer.files[0]);
      let img = document.createElement("img");
      img.src = url;
      if (document.querySelector(".img-prev").hasChildNodes()) {
        document.querySelector("#prev-img-el").remove();
      }
      img.id = "prev-img-el";
      document.querySelector(".img-prev").appendChild(img);
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
      url = URL.createObjectURL(
        document.querySelector(".dropzoneFile").files[0]
      );
      let img = document.createElement("img");
      img.src = url;
      if (document.querySelector(".img-prev").hasChildNodes()) {
        document.querySelector("#prev-img-el").remove();
      }
      img.id = "prev-img-el";
      img.style.width = "150px";
      img.style.height = "150px";
      document.querySelector(".img-prev").appendChild(img);
      console.log(dropzoneFile.value);
      console.log(url);
    };
    return { url, dropzoneFile, drop, selectedFile, v$: useVuelidate() };
  },
  components: {
    DropZone,
    Layout,
    // PageHeader,
  },
  data() {
    return {
      title: "Add Product",
      items: [
        {
          text: "Ecommerce",
          href: "/",
        },
        {
          text: "Add Product",
          active: true,
        },
      ],
      value: null,
      value1: null,
      categories: [],
      branches: [],
      product: {
        name: "",
        qty: 0,
        cost_price: 0,
        critical_qty: 0,
        max_ordered_qty: 0,
        price: 0,
        has_options: 0,
        options: [{}],
      },
      formData: new FormData(),
      submitted: false,
      options: [
        "Alaska",
        "Hawaii",
        "California",
        "Nevada",
        "Oregon",
        "Washington",
        "Arizona",
        "Colorado",
        "Idaho",
        "Montana",
        "Nebraska",
        "New Mexico",
        "North Dakota",
        "Utah",
        "Wyoming",
        "Alabama",
        "Arkansas",
        "Illinois",
        "Iowa",
      ],
      image: "",
      file: "",
      loading: false,
      editor: undefined,
    };
  },
  //   validations: {
  //     product: {
  //       name: {
  //         required: helpers.withMessage("Name is required", required),
  //       },
  //       cost_price: {
  //         required: helpers.withMessage("Cost price is required", required),
  //       },
  //       price: {
  //         required: helpers.withMessage("Price is required", required),
  //       },
  //       qty: {
  //         required: helpers.withMessage("Quantity is required", required),
  //       },
  //     },
  //   },
  methods: {
    responseAlert(text, title, icon) {
      this.$swal.fire({
        title: `${title}`,
        text: ` ${text} `,
        icon: `${icon}`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    deleteOption(index) {
      this.product.options.splice(index, 1);
    },
    getCateogriesAndBranches() {
      
      this.http.post("products/categories-branches", {id:this.$route.params.id}).then((res) => {
        this.categories = res.data.categories;
        this.branches = res.data.branches;
      });
    },
    onAccept(file) {
      this.image = file.name;
      this.file = file;
    },
    async productAdd() {
      // console.log(this.editor?.getData());
      this.loading = true;
      
      // console.log(this.product);
      //         var Data=[{ "NAME": "DATEFORMAT", "VALUE": "yyyy/MM/dd HH:mm:ss" }];
      // var formData = new FormData();
      // for (var i = 0; i < Data.length; i += 1) {
      //     var x=Data[i];
      //     formData.append(x.NAME, x.VALUE);
      //     console.log(formData.get(x.NAME));
      // }
      //   this.submitted = true;
      //   // stop here if form is invalid
      //   this.v$.$touch();

      //   if (this.v$.$invalid) {
      //     return;
      //   } else {
      // console.log({ product: this.product });
      //   let self = this;
      // let formData = new FormData();
      // console.log(this.editor);
      console.log({ inp: document.querySelector("#editorInpt") });
      // console.log(document.querySelector("#editorInpt")?.);
      // console.log(document.querySelector("#editorInpt")?.editor?.getData());
      this.product.descr = `${document.querySelector("#editorInpt").innerText}`;
      let data = this.product;
      let options = [];
      if (this.product.has_options == false) {
        delete data.options;
      } else {
        options = data.options;
      }
      data.image = this.dropzoneFile;
      data.cateogry_id=this.$route.params.id
      // for (let key in data) {
      //   formData.append(`${key}`, data[key]);
      // }
      // console.log(formData.get("name"));
      // for (let key in data) {
      //   console.log(key, " ", formData.get(`${key}`));
      // }
      data.options = JSON.stringify(options);
      console.log(options);
      data.cateogry_id=this.$route.params.id
      this.http.do("products", data).then((res) => {
        if (res.status) {
          this.loading = false;
          console.log("done");
          this.product = {
            name: "",
            qty: 0,
            cost_price: 0,
            critical_qty: 0,
            max_ordered_qty: 0,
            price: 0,
            has_options: 0,
            options: [{}],
          };
          this.dropzoneFile = "";
          if (this.$i18n.locale == "ar") {
            this.responseAlert("تمت إضافة المنتج بنجاح", " تم ", "success");
          } else {
            this.responseAlert("Product Added successfully", "done", "success");
          }
          this.$router.push(`/products/product/${res.data}`);
        } else {
          this.loading = false;
          if (this.$i18n.locale == "ar") {
            this.responseAlert("حدث خطأ اثناء إضافة المنتج", "خطأ", "warning");
          } else {
            this.responseAlert("something went wrong", "error", "warning");
          }
        }
      });
      //   console.log(formData);
      // formData.append("name", this.product.name);
      // formData.append("manufacture_name", this.product.manufacture_name);
      // formData.append("manufacture_brand", this.product.manufacture_brand);
      // formData.append("price", this.product.price);
      // formData.append("image", this.file, this.image);

      //** Add product in api using post method *//
      // axios
      //   .post(`http://localhost:8000/api/products`, formData)
      //   .then((res) => {
      //     return res;
      //   })
      //   .catch((err) => {
      //     return err;
      //   });
      //   }
    },
  },
  mounted() {
    // let self = this;
    // window.ClassicEditor?.create(document.querySelector("#editorInpt"))
    //   .then((editor) => {
    //     editor.setData("");
    //     self.editor = editor;
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  },
  created() {
    this.getCateogriesAndBranches();
  },
};
</script>

<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="row align-items-center">
        <div class="d-flex align-items-center mb-4">
          <router-link :to="`/products`">
            <h4 class="m-0">
              {{ $t("menu.menuitems.products.sub.products") }}
            </h4>
          </router-link>
          <div class="mx-2">\</div>
          <div>{{ $t("products.new.title") }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{ $t("products.new.basic_info") }}</h4>
            <p class="card-title-desc">{{ $t("products.new.fill") }}</p>
            <form @submit.prevent="productAdd" ref="basicForm">
              <div class="row">
                <div class="col-sm-6">
                  <div class="mb-3">
                    <label for="productname">{{
                      $t("products.new.name")
                    }}</label>
                    <input
                      id="productname"
                      v-model="product.name"
                      name="name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && v$.product.name.$error,
                      }"
                    />
                    <div
                      v-if="submitted && v$.product.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="v$.product.name.required.$message">{{
                        v$.product.name.required.$message
                      }}</span>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="price">{{
                      $t("products.new.has_options")
                    }}</label>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="product.has_options"
                        name="has_options"
                        value="0"
                        checked
                        id="sizes_false"
                      />
                      <label class="form-check-label" for="flexRadioDefault1">{{
                        $t("products.new.no")
                      }}</label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="product.has_options"
                        name="has_options"
                        value="1"
                        id="sizes_true"
                      />
                      <label class="form-check-label" for="flexRadioDefault1">{{
                        $t("products.new.yes")
                      }}</label>
                    </div>
                  </div>
                  <div class="mb-3" v-if="!parseInt(product.has_options)">
                    <label for="manufacturername">{{
                      $t("products.new.qty")
                    }}</label>
                    <input
                      id="manufacturername"
                      v-model="product.qty"
                      name="manufacture_name"
                      type="number"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && v$.product.qty.$error,
                      }"
                    />
                    <div
                      v-if="submitted && v$.product.qty.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="v$.product.qty.required.$message">{{
                        v$.product.qty.required.$message
                      }}</span>
                    </div>
                  </div>
                  <div class="mb-3" v-if="!parseInt(product.has_options)">
                    <label for="manufacturername">{{
                      $t("products.new.max_ordered_qty")
                    }}</label>
                    <input
                      id="manufacturername"
                      v-model="product.max_ordered_qty"
                      name="manufacture_name"
                      type="number"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && v$.product.qty.$error,
                      }"
                    />
                    <div
                      v-if="submitted && v$.product.qty.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="v$.product.qty.required.$message">{{
                        v$.product.qty.required.$message
                      }}</span>
                    </div>
                  </div>
                  <div class="mb-3" v-if="!parseInt(product.has_options)">
                    <label for="manufacturerbrand">{{
                      $t("products.new.critical_qty")
                    }}</label>
                    <input
                      id="manufacturerbrand"
                      v-model="product.critical_qty"
                      name="critical_qty"
                      type="number"
                      class="form-control"
                    />
                  </div>
                  <div class="mb-3" v-if="!parseInt(product.has_options)">
                    <label for="price">{{
                      $t("products.new.cost_price")
                    }}</label>
                    <input
                      id="price"
                      name="price"
                      v-model="product.cost_price"
                      :class="{
                        'is-invalid': submitted && v$.product.cost_price.$error,
                      }"
                      type="number"
                      class="form-control"
                    />
                    <div
                      v-if="submitted && v$.product.cost_price.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="v$.product.cost_price.required.$message">{{
                        v$.product.cost_price.required.$message
                      }}</span>
                    </div>
                  </div>
                  <div class="mb-3" v-if="!parseInt(product.has_options)">
                    <label for="price">{{ $t("products.new.price") }}</label>
                    <input
                      id="price"
                      name="price"
                      v-model="product.price"
                      :class="{
                        'is-invalid': submitted && v$.product.price.$error,
                      }"
                      type="number"
                      class="form-control"
                    />
                    <div
                      v-if="submitted && v$.product.price.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="v$.product.price.required.$message">{{
                        v$.product.price.required.$message
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="mb-3">
                    <label class="control-label">{{
                      $t("products.new.branch")
                    }}</label>
                    <select
                      v-model="product.branch_id"
                      class="form-select"
                      name=""
                      id=""
                    >
                      <option
                        :key="branch.id"
                        :value="branch.id"
                        v-for="branch in branches"
                      >
                        {{ branch.name }}
                      </option>
                    </select>
                  </div>

                  <label>{{ $t("products.new.img") }}</label>
                  <DropZone @drop.prevent="drop" @change="selectedFile()" />

                  <div class="my-2 img-prev"></div>
                  <div class="mb-3">
                    <label for="editorInpt">{{
                      $t("products.new.descr")
                    }}</label>
                    <textarea
                      id="editorInpt"
                      class="form-control"
                      v-model="product.descr"
                      rows="5"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="mt-2" v-if="!parseInt(product.has_options)">
                <button
                  v-if="!loading"
                  type="submit"
                  class="btn btn-primary"
                  style="margin-inline-end: 8px"
                >
                  {{ $t("popups.save") }}
                </button>
                <button
                  v-if="loading"
                  disabled
                  type="submit"
                  class="btn btn-primary"
                  style="margin-inline-end: 8px"
                >
                  <b-spinner small></b-spinner>
                  <span class="sr-only">loading ...</span>
                </button>
                <router-link to="products"
                  ><button class="btn btn-secondary">
                    {{ $t("popups.cancel") }}
                  </button></router-link
                >
              </div>
            </form>
          </div>
        </div>

        <div class="card" v-if="parseInt(product.has_options)">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <h4 class="card-title">
                {{ $t("products.new.new_option.title") }}
              </h4>
              <button @click="product.options.push({})" class="btn btn-success">
                <i class="bx bx-plus"></i>
              </button>
            </div>
            <p class="card-title-desc">{{ $t("products.new.fill") }}</p>

            <div class="my-3"></div>
            <form @submit.prevent="productAdd" ref="sizeForm">
              <div
                class="row p-4 mb-3"
                style="background-color: rgb(247 247 247)"
                v-for="(option, index) in product.options"
                :key="option"
              >
                <div class="d-flex justify-content-between mb-2">
                  <div></div>
                  <button
                    :disabled="index == 0"
                    class="btn btn-sm btn-danger"
                    @click="deleteOption(index)"
                  >
                    <i class="bx bx-trash"></i>
                  </button>
                </div>
                <div class="col-sm-6">
                  <div class="mb-3">
                    <label for="metatitle">{{
                      $t("products.new.new_option.name")
                    }}</label>
                    <input
                      v-model="option.name"
                      id="metatitle"
                      name="productname"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="metatitle">{{
                      $t("products.new.new_option.value")
                    }}</label>
                    <input
                      v-model="option.value"
                      id="metatitle"
                      name="productname"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="metatitle">{{
                      $t("products.new.new_option.qty")
                    }}</label>
                    <input
                      v-model="option.qty"
                      id="metatitle"
                      name="productname"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="metatitle">{{
                      $t("products.new.new_option.critical_qty")
                    }}</label>
                    <input
                      v-model="option.critical_qty"
                      id="metatitle"
                      name="productname"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-sm-6">
                  <!-- price config -->
                  <div class="mb-3">
                    <label for="metakeywords">{{
                      $t("products.new.new_option.price")
                    }}</label>
                    <input
                      v-model="option.price"
                      id="metakeywords"
                      name="manufacturername"
                      type="number"
                      class="form-control"
                    />
                  </div>

                  <div class="mb-3">
                    <label for="metakeywords">{{
                      $t("products.new.new_option.cost_price")
                    }}</label>
                    <input
                      v-model="option.cost_price"
                      id="metakeywords"
                      name="manufacturername"
                      type="number"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>

              <button
                v-if="!loading"
                type="submit"
                class="btn btn-primary"
                style="margin-inline-end: 8px"
              >
                {{ $t("popups.save") }}
              </button>
              <button
                v-if="loading"
                disabled
                type="submit"
                class="btn btn-primary"
                style="margin-inline-end: 8px"
              >
                <b-spinner small></b-spinner>
                <span class="sr-only">loading ...</span>
              </button>
              <router-link to="products">
                <button class="btn btn-secondary">
                  {{ $t("popups.cancel") }}
                </button>
              </router-link>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
